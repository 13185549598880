import {
    ApolloClient,
    ApolloLink,
    from,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { PublicationStatus } from '@enums';
import * as Sentry from '@sentry/nextjs';

import { GraphQLError, GraphQLNetworkError } from './errors';

// Handle Apollo errors
const errorLink = onError(
    ({ operation, graphQLErrors, networkError, response }) => {
        Sentry.withScope((scope) => {
            const breadcrumb = {
                category: 'GraphQL',
                message: operation.operationName || 'Unnamed operation',
                data: {
                    variables: operation.variables,
                    query: operation.query.loc?.source.body,
                },
                response,
            };

            scope.addBreadcrumb(breadcrumb);

            if (graphQLErrors) {
                graphQLErrors.forEach((exception) => {
                    console.error(
                        `[GraphQL error]:\n\n Operation: ${JSON.stringify(
                            operation
                        )}\n\nMessage: ${
                            exception.message
                        }\nLocation: ${JSON.stringify(
                            exception.locations
                        )}\n\nPath: ${JSON.stringify(
                            exception.path
                        )}\n\nExtensions: ${JSON.stringify(
                            exception.extensions
                        )}`
                    );
                    scope.captureException(new GraphQLError(exception.message));
                });
            }
            if (networkError) {
                console.error(`[Network error]: ${networkError}`);
                const { message, ...details } = networkError;
                scope.addBreadcrumb({ category: 'GraphQL', ...details });
                scope.captureException(new GraphQLNetworkError(message));
            }
        });
    }
);
const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_STRAPI_GRAPHQL,
    includeExtensions: true,
});

const previewLink = new ApolloLink((operation, forward) => {
    operation.extensions = { publicationStatus: PublicationStatus.Draft };

    return forward(operation);
});

const dataLink = new ApolloLink((operation, forward) => {
    operation.extensions = { publicationStatus: PublicationStatus.Live };

    return forward(operation);
});

function createApolloClient(isPreview: boolean) {
    return new ApolloClient({
        ssrMode: typeof window === 'undefined',
        cache: new InMemoryCache(),
        link: isPreview
            ? from([errorLink, previewLink, httpLink])
            : from([errorLink, dataLink, httpLink]),
    });
}

export default createApolloClient;
