import NewTabLink from '@components/utils/links/newTabLink';
import { PIMSTER_STATIC_CDN } from '@constants';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';
import { HStack, HStackProps } from '@lib/uikit';
import NextImage from 'next/legacy/image';
import { useRouter } from 'next/router';
import { FC } from 'react';

type Variant = 'light' | 'dark';

type Size = 'small' | 'normal';

interface Props extends HStackProps {
    variant?: Variant;
    size?: Size;
    priority?: boolean;
}

const Text = styled.p<{ variant?: Variant; size: Size }>`
    font: ${({ theme }) => theme.fonts.body};
    font-size: ${({ size }) => (size === 'normal' ? 'normal' : 'x-small')};
    color: ${({ theme, variant }) =>
        variant === 'light'
            ? theme.palette.common.white
            : theme.palette.text.secondary};
`;

const Link = styled(NewTabLink)<{ size: Size }>`
    position: relative;
    aspect-ratio: 16 / 10;
    width: ${({ size }) => (size === 'normal' ? '5rem' : '3rem')};
`;

const Image = styled(NextImage)<{ variant: Variant }>`
    filter: ${({ variant }) => (variant === 'light' ? 'invert(1)' : '')};
`;

const PoweredBy: FC<Props> = ({
    variant,
    size = 'normal',
    priority = false,
    ...rest
}: Props) => {
    const { query } = useRouter();
    const campaignName = query.company;
    const pimsterURL = `https://pimster.app?utm_source=pim&utm_campaign=${campaignName}&utm_medium=footer`;
    const theme = useTheme();

    return (
        <HStack
            aria-label='Made by Pimster'
            justifyContent='center'
            spacing={size === 'normal' ? 'xs' : 'xxs'}
            {...rest}
        >
            <Text variant={variant} size={size}>
                Powered by
            </Text>
            <Link
                href={pimsterURL}
                destination_name='Pimster Landing Page'
                destination_category={
                    OutboundLinkDestinationCategory.PimsterDocument
                }
                shape={OutboundLinkShape.Visual}
                context={OutboundLinkContext.Footer}
                size={size}
            >
                <Image
                    priority={priority}
                    src={`${PIMSTER_STATIC_CDN}/brand/logo/logo-transparent-black.png`}
                    alt='Pimster'
                    layout='fill'
                    objectFit='contain'
                    sizes={size === 'normal' ? '5rem' : '3rem'}
                    variant={
                        variant || theme.palette.mode === 'dark'
                            ? 'light'
                            : 'dark'
                    }
                />
            </Link>
        </HStack>
    );
};

export default PoweredBy;
