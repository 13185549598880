export class GraphQLError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'GraphQLError';
    }
}

export class GraphQLNetworkError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'GraphQLNetworkError';
    }
}
